<template>
  <div>
    <AppPayBanner />
    <footer class="footer">
      <div class="sns-icon">
        <a href="https://twitter.com/hinakoiofficial" target="_blank" class="twitter-logo">
          <img src="@/assets/images/sns/btn_x.png" alt="">
        </a>
        <a href="https://www.tiktok.com/@hinakoi_official?lang=ja-JP" target="_blank" class="twitter-logo">
          <img src="@/assets/images/sns/btn_tiktok.png" alt="">
        </a>
        <a href="https://www.instagram.com/hinakoi_official/" target="_blank" class="twitter-logo">
          <img src="@/assets/images/sns/btn_insta.png" alt="">
        </a>
        <a href="https://lin.ee/dJbMwQk" target="_blank" class="line-logo">
          <img src="@/assets/images/sns/btn_line.png" alt="">
        </a>
        <a href="https://www.youtube.com/channel/UC4IONb6pMWed0F7BC6niliQ/" target="_blank" class="line-logo">
          <img src="@/assets/images/sns/btn_youtube.png" alt="">
        </a>
      </div>
      <div class="footer-detail">
        <div>
          <table>
            <tr>
              <th>タイトル：</th>
              <td>ひなこい</td>
            </tr>
            <tr>
              <th>価格：</th>
              <td>基本無料（アイテム課金型）</td>
            </tr>
            <tr>
              <th>配信プラットホーム：</th>
              <td>App Store、Google Play</td>
            </tr>
          </table>
        </div>
        <div class="privacy">
          <a href="/commerce.html" target="_blank" class="privacy-link">特定商取引法に基づく表示</a>
          <a href="/payment.html" target="_blank" class="privacy-link">資金決済法に基づく表示</a><br>
          <a href="/commerce.html#cancel" target="_blank" class="privacy-link">キャンセル・返品</a>
          <a href="/terms.html" target="_blank" class="privacy-link">利用規約</a>
          <div class="privacy-link" @click="openPopup">プライバシーポリシー</div>
        </div>
        <div class="contact-link">
          <p>お問い合わせ窓口：<a href="mailto:support_hinakoi@10antz.jp">support_hinakoi@10antz.jp</a></p>
        </div>
      </div>
      <div id="copyright" class="copyright">
        ©Seed&Flower LLC/Y&N Brothers Inc. ©10ANTZ Inc.
      </div>
    </footer>
    <Transition name="fade">
      <div class="privacy-popup" v-if="showPopup">
      <div @click="showPopup = false" class="privacy-popup-close">×</div>
      <div class="privacy-popup-main">
        <div>
          <p><span style="font-weight: bold">プライバシーポリシー</span></p>
          <br>
          <p><span style="font-weight: bold">1．総則</span></p>
          <p><span>株式会社10ANTZ（以下「当社」といいます。）は、個人情報保護の重要性を認識し、その保護の実現のため、個人情報の保護に関する法津（以下「個人情報保護法」といいます。）、各省庁ガイドラインその他関連する法令等を遵守します。当社は、お客様（以下「ユーザー」といいます。）からお預かりする個人情報につきまして、以下のプライバシーポリシー（以下「本ポリシー」といいます。）に基づき、適正に取り扱います。</span></p>
          <p><span>本ポリシーは、当社が提供するスマートフォン向けアプリ「ひなこい」及び「ひなこい」に関連し当社が提供するウェブサイト及びサービス（以下、個別に又は総称して「本サービス」といいます。）の利用に適用されます。</span></p>
          <p><span>なお、本ポリシーにおける個人情報等の定義は、特に断らない限り、個人情報保護法に定める定義によるものとします。</span></p>
          <br>
          <p><span style="font-weight: bold">2．個人情報の取得</span></p>
          <p><span>当社は、ユーザーによる本サービスの利用に関連して、以下の個人情報を取得することがあります。</span></p>
          <p><span>＜ユーザーから直接ご提供いただく情報＞</span></p>

          <p><span>● 氏名、生年月日、住所、ニックネームその他のプロフィール情報</span></p>

          <p><span>● 電話番号、メールアドレス</span></p>

          <p><span>● 本サービス内で行われたユーザーと当社とのコミュニケーション、又はユーザー間のコミュニケーションの内容</span></p>

          <p><span>● 当社に対するお問い合わせ内容</span></p>

          <p><span>● その他、本サービスの提供のために必要な情報</span></p>

          <p><span>＜本サービス利用時に自動的に取得する情報＞</span></p>

          <p><span>● ユーザーID</span></p>

          <p><span>● 端末情報</span></p>

          <p><span>● Cookieないし匿名ID</span></p>

          <p><span>● IPアドレス、アプリ等の操作履歴等のログ情報、行動履歴情報</span></p>

          <p><span>● アプリケーション内課金履歴</span></p>

          <p><span>● 情報端末等から送受信される位置情報、通信情報</span></p>

          <p><span>＜外部サービスとの連携時に自動的に取得する情報＞</span></p>

          <p><span>● 外部サービス連携によるID、アクセストークン、ニックネーム、性別、その他会員情報</span></p>

          <p><span>当社は、個人情報の取得にあたっては、偽りその他不正の手段によらず、適正な方法により取得します。</span></p>

          <br>

          <p><span style="font-weight: bold">3． 個人情報の利用目的</span></p>

          <p><span>当社は、第2項に定める個人情報を以下の利用目的及び別途通知又は公表する利用目的で利用することができるものとします。個人情報保護法その他の法令により認められる事由がある場合を除き、本人の同意がない限り、この範囲を超えて個人情報を利用しません。</span></p>

          <p><span>● 本サービスの提供、維持、改善のため</span></p>

          <p><span>● 年齢に応じた課金上限額の設定及び課金計算のため</span></p>

          <p><span>● 本人確認、認証サービスのため</span></p>

          <p><span>● アフターサービス、問い合わせ、苦情対応のため</span></p>

          <p><span>● 本サービスの利用状況の引き継ぎ</span></p>

          <p><span>● アンケートの実施のため</span></p>

          <p><span>● 懸賞やキャンペーン等の実施、及びそれに伴う特典商品等の発送のため</span></p>

          <p><span>● サービス開発及びサービス向上を目的としたマーケティングデータの調査、統計、分析のため</span></p>

          <p><span>● 新サービス、新機能の開発のため</span></p>

          <p><span>● システムの維持、不具合対応のため</span></p>

          <p><span>● 利用規約違反行為の是正のため</span></p>

          <p><span>● 新サービスや新製品等の情報提供のため</span></p>

          <p><span>● 統計データの作成及び当該統計データの第三者提供のため</span></p>

          <p><span>● 本ポリシーに記載された第三者提供のため</span></p>

          <p><span>● その他本サービス実現のため</span></p>

          <p><span>● その他、上記の利用目的に付随する目的</span></p>

          <br>

          <p><span style="font-weight: bold">4． 第三者提供について</span></p>

          <p><span>当社は、以下の場合を除き、個人データを第三者に提供しません。</span></p>

          <p><span>● 本人の事前の同意を得た場合</span></p>

          <p><span>● 法令に基づく場合</span></p>

          <p><span>● 人の生命、身体、財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき</span></p>

          <p><span>● 公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき</span></p>

          <p><span>● 国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</span></p>

          <p><span>● 当該第三者が学術研究機関等である場合であって、当該第三者が当該個人情報を学術研究目的で取り扱う必要があるとき（当該個人情報を取り扱う目的の一部が学術研究</span><span
          >で</span><span>ある場合を含み、個人の権利利益を不当に侵害するおそれがある場合を除く。）</span></p>

          <p><span>● その他法律上認められている場合</span></p>

          <br>

          <p><span style="font-weight: bold">5． 情報収集モジュールについて</span></p>

          <p><span>当社は、本サービスの利用状況及び各種施策の調査・分析のため、本サービスに情報収集モジュールを組み込むことがあります。当社は、情報収集モジュールによりユーザーに関する情報を取得し、個人データとして利用します。当社は、情報収集モジュールの事業者に対し、情報収集モジュールにより取得した情報（当該情報だけで特定の個人を識別することができる情報は含みません）を提供する場合があります。提供された情報は、各情報収集モジュール事業者の定めるプライバシーポリシーその他の規定に基づき管理されます。</span></p>

          <br>

          <p><span style="font-weight: bold">6．Cookieの利用について</span></p>

          <p><span>Cookieとは、任意の文字が書かれた小さなテキストファイルであり、ユーザーがウェブサイトを閲覧した際に、ウェブサーバーと本人のインターネット閲覧ソフト（ブラウザ）との間でやりとりされ、ユーザーの使用する端末に保存されるものです。これを利用することにより、ユーザーの閲覧履歴、サービス利用履歴、位置情報等、特定の個人を識別することができない属性情報を取得することがあります。</span></p>

          <p>ユーザーは、ブラウザの設定により、事前に、Cookieを使用しているサイトであることを表示したり、Cookieの無効化及び保存済みのCookieを削除等をすることができます。Cookieの利用を拒否し、又は、Cookieを削除した場合、ウェブサイトでご利用いただくことができる機能が制限される可能性がありますので、ご了承ください。</p>

          <p><span>当社ウェブサイトでは、ウェブサイトの利用状況を把握するためにGoogle社の提供するサービスであるGoogle
Analyticsを利用しています。Google Analyticsは、当社ウェブサイトが発行するCookieを利用して当社ウェブサイトの利用状況を分析します。当社は、Google社からその分析結果を受け取り、ユーザーの当社ウェブサイトの訪問状況を把握します。Google
Analyticsにより収集、記録、分析されたユーザーの情報には、特定の個人を識別する情報は一切含まれません。また、それらの情報は、Google社により同社のプライバシーポリシーに基づいて管理されます。</span></p>

          <p><span>Google
Analytics情報収集方法及び利用方法の詳細については、Google社の以下のページをご覧ください。</span></p>

          <p><span><a
              href="https://policies.google.com/technologies/partner-sites?hl=ja">https://policies.google.com/technologies/partner-sites?hl=ja</a><p></p></span></p>

          <p><span>ユーザーが当社ウェブサイトにおいてGoogle
Analyticsによる情報収集を拒否される場合は、Google社の以下のページより、 Google Analyticsを無効化することができます。</span></p>

          <p><span><a
              href="https://tools.google.com/dlpage/gaoptout?hl=ja">https://tools.google.com/dlpage/gaoptout?hl=ja</a><p></p></span></p>

          <br>

          <p><span style="font-weight: bold">7． 個人情報の開示、訂正等の権利について</span></p>

          <p><span>当社は、本人から、当該本人が識別される保有個人データについて、利用目的の通知、開示、訂正・追加・削除、利用の停止・消去・第三者への提供の停止を請求された場合、及び、第三者提供記録の開示を請求された場合、本人からの請求であることを確認の上、個人情報保護法その他の法令の定め及び当社が定める所定の手続に従って適切に対応します。</span></p>

          <p><span>但し、各請求が個人情報保護法その他の法令の定める要件を満たさない場合、又は、個人情報保護法その他の法令により各請求を拒絶することが認められる事由がある場合には、請求に添えないことがございます。</span></p>

          <p><span>また、正当な理由なく同内容の請求が繰り返される等、権利の濫用と認められるとき、さらには、過度な技術的作業を要するときは、請求に添えないことがございます。</span></p>

          <br>

          <p><span style="font-weight: bold">8． 個人情報を保護するための体制について</span></p>

          <p><span>当社は、取り扱う個人情報の漏洩、滅失または毀損等を防止するため、その他取り扱う個人情報の保護のため、個人情報ファイルへのアクセス制限の実施、アクセスログの記録、また外部からの不正アクセス防止のためのセキュリティソフトの導入等、個人情報の安全管理のために必要かつ適切な措置を講じます。また、当社は、個人情報の取</span><span
          >り</span><span>扱いに関し、役員及び従業員に対して適切な監督を行うとともに、個人情報の取</span><span
          >り</span><span>扱いを委託する場合には、委託先が個人情報を適切に管理するよう監督します。</span></p>

          <br>

          <p><span style="font-weight: bold">9． 本プライバシーポリシーの変更</span></p>

          <p><span>当社は、本ポリシーを随時変更することができるものとします。変更が行われた場合には、当社のホームページにて告知致します。</span></p>

          <br>

          <p><span style="font-weight: bold">10． お問い合わせ先</span></p>

          <p><span>本ポリシーに関するお問い合わせ先</span></p>

          <p><a href="https://10antz.co.jp/contact/">https://10antz.co.jp/contact/</a></p>

          <br>

          <p>改定日： 2022年8月10日</p>

          <br>

          <p><span>株式会社10ANTZ＜テンアンツ＞</span></p>

          <p><span>〒150-0002</span></p>

          <p><span>東京都渋谷区渋谷3-12-18 渋谷南東急ビル4階</span></p>

          <p><span>代表取締役&emsp;髙澤真</span></p>

          <br>

        </div>
      </div>
    </div>
    </Transition>
  </div>
</template>
<script>
import AppPayBanner from '@/components/AppPayBanner.vue';

export default {
  name: 'GlobalFooter',
  components: { AppPayBanner },
  data() {
    return {
      showPopup: false
    }
  },
  methods: {
    openPopup() {
      this.showPopup = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.sns-icon {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 5px;
  font-size: 35px;
  a {
    width: 40px;
    padding: 10px;
    text-decoration: none;
    color: #444;
    img {
      width: 100%;
    }
  }
}
.footer-detail {
  text-align: center;
  background: #5bdac5;
  color: white;
  font-size: 14px;
  padding: 20px 0;
  padding-bottom: 120px;
  line-height: 1.5em;
  table {
    margin: 0 auto;
    th {
      text-align: right;
    }
    td {
      text-align: left;
    }
  }
}
.privacy-link {
  font-size: 12px;
  margin: 0 5px;
  color: white;
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
}
.contact-link {
  font-size: 14px;
  margin: 0 5px;
  color: white;
  display: inline-block;
  a {
    color: white;
  }
}
.copyright {
  text-align: center;
  padding: 5px;
  font-size: 12px;
  background: #9999a2;
  color: white;
}
.privacy-popup {
  background: #f2f2f2;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  box-sizing: border-box;
  padding: 20px;
  overflow-y: scroll;
  padding-top: 40px;
  overflow-wrap: break-word;
}
.privacy-popup-main p {
  line-height: 20px;
}
.privacy-popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}
.privacy-agree {
  margin: 15px;
  display: flex;
  align-items: center;
  justify-content: center
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media screen and (max-width: 1023px) {
  .sns-icon {
    a {
      width: 30px;
      padding: 10px;
      text-decoration: none;
      color: #444;
      img {
        width: 100%;
      }
    }
  }
  .footer-detail {
    padding-bottom: 70px;
  }
}
</style>
