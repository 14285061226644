export default {
  data() {
    return {
      replaceWidth: 1024,
      isPC: false
    };
  },
  created() {
    this.reportWindowSize();
  },
  mounted() {
    window.addEventListener('resize', this.reportWindowSize);
  },
  methods: {
    reportWindowSize() {
      const windowWidth = window.innerWidth;
      this.isPC = windowWidth >= this.replaceWidth;
    }
  }
};
