<template>
  <div class="app-pay-banner">
    <a href="https://app-pay.jp/app/hinakoi/" target="_blank">
      <img src="@/assets/images/apppay_banner.png">
    </a>
  </div>
</template>
<script>
export default {
  name: 'AppPayBanner'
}
</script>


<style scoped lang="scss">
.app-pay-banner {
  position: fixed;
  top: 93%;
  left: 50%;
  transform: translate(-50%, -50%);
  a {
    display: block;
  }
}
@media screen and (max-width: 1023px) {
  .app-pay-banner {
    width: 70%;
    a {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
}
</style>
