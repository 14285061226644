<template>
  <header id="header">
    <nav id="nav" class="header-wrap">
      <a href="/" class="header-logo-wrap">
        <h1>
          <img class="header-logo" src="@/assets/images/header/pc_img_logo.png" alt="ひなこい" />
        </h1>
      </a>
      <ul class="header-wrap-menus">
        <li>
          <a :class="{'is-active' : pathname === '/'}" href="/"><span>TOP</span></a>
        </li>
        <li><a href="https://twitter.com/hinakoiofficial" target="_blank"><span>NEWS</span></a></li>
        <li><a :class="{'is-active' : pathname === '/story.html'}" href="/story.html"><span>STORY</span></a></li>
        <li><a :class="{'is-active' : pathname === '/system.html'}" href="/system.html"><span>SYSTEM</span></a></li>
        <li><a :class="{'is-active' : pathname === '/classmate.html'}" href="/classmate.html"><span>CLASSMATE</span></a></li>
        <li><a :class="{'is-active' : pathname === '/movie.html'}" href="/movie.html"><span>MOVIE</span></a></li>
<!--        <li><a :class="{'is-active' : pathname === '/campaign.html'}" href="/campaign.html"><span>CAMPAIGN</span></a></li>-->
      </ul>
      <img class="hamburger-menu" src="@/assets/images/header/sp_btn_menu.png" alt="hamburger menu" @click="showOverlay = true"/>
    </nav>

    <section id="overlay-nav" :class="['overlay', {show: showOverlay} ]">
      <div class="close-menu" @click="showOverlay = false">&times;</div>
      <div class="overlay-content">
        <ul class=overlay-tabs>
          <li><a href="https://twitter.com/hinakoiofficial" target="_blank">NEWS</a></li>
          <li><a href="/story.html">STORY</a></li>
          <li><a href="/system.html">SYSTEM</a></li>
          <li><a href="/classmate.html">CLASSMATE</a></li>
          <li><a href="/movie.html">MOVIE</a></li>
<!--          <li><a href="/campaign.html">CAMPAIGN</a></li>-->
        </ul>
      </div>
    </section>
  </header>
</template>

<script>
export default {
  name: 'GlobalHeader',
  data() {
    return {
      showOverlay: false,
      pathname: '/'
    }
  },
  mounted() {
    this.pathname = window.location.pathname;
  }
}
</script>

<style lang="scss" scoped>
/*ヘッダーの設定*/
header {
  position: fixed;
  top:0;
  margin:0;
  width: 100%;
  z-index: 100; /*最上位に配置*/
}

/*PCヘッダーの設定*/
.header-wrap {
  background-color: #ffffff;
  display: flex;
  height: 70px;
  align-items: stretch;
  .header-logo-wrap {
    display: flex;
    align-items: center;
    width: 496px;
    padding: 0 0 0 20px;
    h1 {
      margin: 0;
      line-height: 0;
    }
    .header-logo {
      width: 100%;
      height: auto;
    }
  }
}

.header-wrap-menus {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  width: 100%;
  margin: 0;
  li {
    list-style: none;
    margin-right: 32px;
    position: relative;
    &:last-child {
      margin-right: 15px;
    }
    a {
      text-decoration: none;
      position: relative;
      z-index: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        color: #9999a2;
        font-weight: bold;
        font-size: 20px;
        font-family: FOT;
        &:before {
          opacity: 0;
          content: '';
          background-size:contain;
          position: absolute;
          display:inline-block;
          background:url("@/assets/images/header/pc_icon_header.png") no-repeat;
          width:40px;
          height:40px;
          z-index: -1;
          top: calc(50% - 20px);
          left: -18px;
        }
        &:after {
          content: '';
          position: absolute;
          opacity: 0;
          left: 2%;
          bottom: 4px;
          width: 96%;
          height: 5px;
          background: #88cbff;
        }
      }
      &.is-active,
      &:hover {
        span {
          color: #5ba7f2;
          &:before {
            -webkit-transition: all 1s;
            -moz-transition:    all 1s;
            -ms-transition:     all 1s;
            -o-transition:      all 1s;
            transition:         all 1s;
            opacity: 1;
          }
          &:after {
            -webkit-transition: all 1s;
            -moz-transition:    all 1s;
            -ms-transition:     all 1s;
            -o-transition:      all 1s;
            transition:         all 1s;
            opacity: 1;
          }
        }
      }
    }
  }
}

/*SPヘッダーの設定*/
.overlay {
  background-color: rgba(153,153,162, 0.7);
  height: 0;
  top: 0;
  right: 0;
  width: 65%;
  position: fixed;
  overflow-y: scroll;
  transition: 0.5s;
  border-radius: 2px;
  &.show {
    height: 400px;
  }
  .overlay-nav{
    width: 100%;
  }
  .overlay-content {
    margin-top: calc(100% - 55vw);
    position: relative;
    top: 30px;
    width: 100%;
  }
  .overlay-tabs {
    padding-left: 0;
    li {
      list-style: none;
      margin: 0 15px 25px 15px;
      border-bottom: solid 1px white;
      a {
        display:block; /* 遷移反応をリスト全体にする */
        color: #ffffff;
        font-size: calc((100% + 0.25vw));
        font-family: FOT;
        text-decoration: none;
        text-align: right;
        transition: 0.3s;
      }
    }
  }
  .close-menu {
    color: #ffffff;
    font-size: 60px;
    font-weight:200;
    position: absolute;
    right: 14px;
    top: -10px;
    text-decoration: none;
  }
}

/*ハンバーガーメニューの設定*/
.hamburger-menu {
  display: none;
  &:hover {
    cursor: pointer;
  }
}

/*SNSアイコンの設定*/
.sns-icon{
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 5px;
  font-size:35px;
}

.sns-icon a{
  width: 30px;
  padding: 10px;
  text-decoration: none;
  color:#444;
}
.sns-icon a img{
  width: 100%;
}

.sns-icon .fa-twitter:hover{
  color:#55acee;
}

.sns-icon .fa-youtube:hover{
  color:#fc0d1c;
}

.sns-icon .fa-line:hover{
  color:#00c300;
}

@media screen and (max-width: 1023px) {
  body{
    margin-top: 0;
  }

  .header-wrap {
    justify-content: space-between;
    height: 50px;
    align-items: center;
    box-sizing: border-box;
    padding: 0 10px;
    .header-logo-wrap {
      width: 198px;
      padding: 0;
    }
  }
  .header-wrap-menus {
    display: none;
  }
  .hamburger-menu {
    display: block;
    height: 35%;
  }
}

</style>
